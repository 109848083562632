import React from 'react'
import { Col, Row } from 'antd';
import './blockComparedCards.css'

import { ComparedCard } from '../comparedCard/comparedCard';

const blockComparedCardsGreed = [1,2,3,4,5,6,7,8]

export function BlockComparedCards(props) {

    // console.log(props.data);
    return (
        <>
        {props.type == "content" &&
            <div className="block-compared-cards-wrapper">
                <h4 className="block-compared-cards-header">{props.title}</h4>
                <Row gutter={{ 'xs': 8, 'sm': 8, 'md': 16, 'lg': 32, 'xl': 40, 'xxl': 64 }}>
                    <Col span={12}>
                        <ComparedCard type = "content" data = {props.data["primary"]}/>
                    </Col>
                    <Col span={12}>
                        <ComparedCard type = "content" data = {props.data["analyzed"]}/>
                    </Col>
                </Row>
            </div>
        }
        {props.type == "loader" &&
            <div className="block-compared-cards-wrapper">
                <Row gutter={{ 'xs': 8, 'sm': 8, 'md': 16, 'lg': 32, 'xl': 40, 'xxl': 64 }}>
                    <Col span={12}>
                        <ComparedCard type = "loader" data = {blockComparedCardsGreed}/>
                    </Col>
                    <Col span={12}>
                        <ComparedCard type = "loader" data = {blockComparedCardsGreed}/>
                    </Col>
                </Row>
            </div>
        }
        </>
    )
}