import React, { useState, useEffect } from 'react'
import { LogoutOutlined, LoginOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useAuth } from '../../auth'
import {
    Link, useHistory
} from 'react-router-dom'

import { Loader } from '../loader/loader';

export function LogInLogOutButton(props) {
    let history = useHistory()
    const auth = useAuth();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false)
      }, [auth.user]);

    return (
        <React.Fragment>
            {auth.user ? (
                <React.Fragment>
                    { !loading ? (
                    <Button key="logout" icon={<LogoutOutlined />} onClick={() => {
                            auth.signout(() => history.push('/'));
                            setLoading(true)
                        }}>
                        Выйти
                    </Button>
                    ) :
                    ( <Loader type='spin'/> )}
                </React.Fragment>

            ) : (
                    <Button key="login" type = {props.type == "primary" ? "primary" : ""} icon={<LoginOutlined />} onClick={() => {
                            history.push('/login');
                        }}>
                        Войти
                    </Button>
                )}
        </React.Fragment>)
}