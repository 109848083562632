import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
  useHistory, useLocation,
} from 'react-router-dom'

import { MainMenu } from './components/menu/menu'
import { ProvideAuth, useAuth, authContext } from './auth'

import Catalog from './pages/Catalog'
import FindAnalogs from './pages/FindAnalogs'
import Classification from './pages/Classification'
import ProductPortfolio from './pages/ProductPortfolio'
import Favorites from './pages/Favorites'
import Welcome from './pages/Welcome'
import LoginPage from './pages/Login'

function PrivateRoute({ children, hasRole, ...rest }) {
  const auth = useAuth();
  return (
      <Route
          {...rest}
          render={({ location }) =>
              auth.isAutorised() && auth.hasRole(hasRole) ? 
                (children) 
                : 
                auth.isAutorised() && !auth.hasRole(hasRole) ?
                 <div>Access denied</div> 
                  : (
                    <Redirect
                        to={{
                            pathname: '/welcome',
                            state: { from: location }
                        }}
                    />
                  )
          }
      />
  )
}

// This is for pages like your.app/books/the-bible, wthere 'the-bible' in nan Object ID
//
// const Child = ({ match }) => {
//   return (
//   <div>Object ID: {match.params.id}</div>
//   )
// }

export default function App() {
  return (
    <ProvideAuth>
      <Router>
        <MainMenu />
        <Switch>

         {/* Public pages */}
          <Route path="/login">
            <LoginPage />
          </Route>
          <PrivateRoute exact path="/">
            <Catalog />
          </PrivateRoute>
          <Route exact path="/welcome">
            <Welcome />
          </Route>
          <PrivateRoute exact path="/find-analogs">
            <FindAnalogs />
          </PrivateRoute>
          <PrivateRoute exact path="/product-portfolio">
            <ProductPortfolio />
          </PrivateRoute>
          <PrivateRoute exact path="/classification">
            <Classification />
          </PrivateRoute>

          {/* Pages for any authorised user */}
          <PrivateRoute path="/favorites">
            <Favorites />
          </PrivateRoute>
          {/*<PrivateRoute path="/websocket">
            <Websocket />
          </PrivateRoute>*/}

          {/* Pages for users, who have role == 'admin'. You can apply any other value here */}
          {/*<PrivateRoute path="/admin" hasRole={'admin'}>
            <AdminPage />
          </PrivateRoute>/}

          {/* This is for pages like your.app/books/the-bible, wthere 'the-bible' in an Object ID */}
          {/* <Route exact path="/table/:id" component={Child}/> */}
        </Switch>
      </Router>
    </ProvideAuth>
  );
}
