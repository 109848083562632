import React from 'react';
import {
  AimOutlined,
  DeploymentUnitOutlined,
  ExperimentOutlined,
  FolderOpenOutlined,
  SafetyOutlined,
  ThunderboltOutlined
} from '@ant-design/icons';
import { Tabs } from 'antd';

import { SearchableTree } from '../components/searchableTree/searchableTree';
// import { SimpleTree } from '../components/simpleTree/simpleTree';

const addIcons = (data, type) => {
  if (type == "treeMoaCombinations") {
      data.map(lvl_1 => 
        { 
          lvl_1.children.map(lvl_2 => {
            lvl_2.icon = <AimOutlined />
            lvl_2.children.map(lvl_3 =>{
              lvl_3.icon = <ExperimentOutlined />
              lvl_3.children.map(lvl_4 => {
                lvl_4.icon = <DeploymentUnitOutlined />
                lvl_4.children.map(lvl_5 => {
                  lvl_5.icon = <SafetyOutlined />
                })
              })
            })
          })
        }
      )
  } else {
    data.map(lvl_1 => 
        { 
          lvl_1.icon = <AimOutlined />
          lvl_1.children.map(lvl_2 => {
            lvl_2.icon = <ExperimentOutlined />
            lvl_2.children.map(lvl_3 =>{
              lvl_3.icon = <DeploymentUnitOutlined />
              lvl_3.children.map(lvl_4 => {
                lvl_4.children.map(lvl_5 => {
                  lvl_5.icon = <DeploymentUnitOutlined />
                  lvl_5.children.map(lvl_6 => {
                    lvl_6.icon = <SafetyOutlined />
                  })
                })
              })
            })
          })
        }
      )
  }
  
}
const treeMoaCombinations = require("../data/treeMoaCombinations.json");
const treeFRAC = require("../data/treeFRAC.json");
const treeHRAC = require("../data/treeHRAC.json");
const treeIRAC = require("../data/treeIRAC.json");
const dataSrc = [treeMoaCombinations, treeFRAC, treeHRAC, treeIRAC];
const dataStr = ["treeMoaCombinations", "treeFRAC", "treeHRAC", "treeIRAC"];

dataSrc.map((src, indx) => {
    addIcons(src, dataStr[indx])
})

export default function Classification() {
    return (
        <div className="content">
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Классификация сочетаний MOA" key="1">
                    <SearchableTree data = {dataSrc[0]} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="FRAC – фунгициды" key="2">
                    <SearchableTree data = {dataSrc[1]} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="HRAC – гербициды" key="3">
                    <SearchableTree data = {dataSrc[2]} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="IRAC – инсектициды" key="4">
                    <SearchableTree data = {dataSrc[3]} />
                </Tabs.TabPane>
            </Tabs>
        </div>

    )
}