import React, { useState } from "react";
import { Select } from 'antd';

const { Option, OptGroup } = Select;

const protectedObjectsData = require("../../data/protectedObjects.json");
const pesticideTypesData = require("../../data/pesticideTypes.json");
const aimsData = require("../../data/aims.json");
const brandsData = require("../../data/brands.json");
const moaCombinationsData = require("../../data/moaCombinations.json");
const chemicalClassesCombinationsData = require("../../data/chemicalClassesCombinations.json");
const componentsCombinationsData = require("../../data/componentsCombinations.json");

export function FiltersBlock (props) {

	console.log(`вызов FiltersBlock`);
	const [open, setOpen] = useState({'protectedObjects': false, 'pesticideTypes': false, 'aims': false, 'brands': false});

	const handleChange = (event, data, selectName) => {
		var keys = '';
		var labels = [];
	    for (var i = data.length - 1; i >= 0; i--) {
	      keys = keys + "," + data[i].key;
	      labels.push(data[i].label)
	    }
	    keys = keys.slice(1);
	    console.log(event);

		props.onChange({selectName: selectName, keys: keys, values: event, labels: labels})
		console.log(`Ура, фильтр работает!`);
	}

	const handleChangeProtectedObject = (event, data) => {
		handleChange(event, data, 'protectedObjects');
	}
	const handleChangePesticideType = (event, data) => {
		handleChange(event, data, 'pesticideTypes');
	}
	const handleChangeAim = (event, data) => {
		handleChange(event, data, 'aims');
	}
	const handleChangeBrand = (event, data) => {
		handleChange(event, data, 'brands');
	}
	const handleChangeMOACombinations = (event, data) => {
		handleChange(event, data, 'moaCombinations');
	}
	const handleChangeChemicalClassesCombinations = (event, data) => {
		handleChange(event, data, 'chemicalClassesCombinations');
	}
	const handleChangeComponentsCombinations = (event, data) => {
		handleChange(event, data, 'componentsCombinations');
	}

	const onSelectHandler = (event) => {
		setOpen({'protectedObjects': false, 'pesticideTypes': false, 'aims': false, 'brands': false})
	}

  return (
  	<div className="filters-block">
	  	<label><h4>Защищаемые объекты</h4></label>
	    <Select
	      	mode="multiple"
	      	showArrow
	      	allowClear
	      	style={{
	        	width: '100%',
	        	marginTop: 5,
	        	marginBottom: 20,
	      	}}
	      	placeholder="Выберите объекты"
	      	onChange={handleChangeProtectedObject}
	      	notFoundContent="объекты не найдены"
	      	onSelect={onSelectHandler}
	      	open={open['protectedObjects']}
          	disabled={props.disabled}
	      	onDropdownVisibleChange={(visible) => setOpen({'protectedObjects': visible, 'pesticideTypes': false, 'aims': false, 'brands': false})}
	    	options={protectedObjectsData}
		/>
	  	
	    <label><h4>Тип препарата</h4></label>
	    <Select
	      	mode="multiple"
	      	showArrow
	      	allowClear
	      	style={{
	        	width: '100%',
	        	marginTop: 5,
	        	marginBottom: 20,
	      	}}
	      	placeholder="Выберите типы"
	      	onChange={handleChangePesticideType}	
	      	notFoundContent="типы не найдены"
	      	onSelect={onSelectHandler}
	      	open={open['pesticideTypes']}
          	disabled={props.disabled}
	      	onDropdownVisibleChange={(visible) => setOpen({'protectedObjects': false, 'pesticideTypes': visible, 'aims': false, 'brands': false})}       
	    	options={pesticideTypesData}
		/>

	    <label><h4>Цели обработки</h4></label>
	    <Select
	      	mode="multiple"
	      	showArrow
	      	allowClear
	      	style={{
	        	width: '100%',
	        	marginTop: 5,
	        	marginBottom: 20,
	      	}}
	      	placeholder="Выберите цели"
	      	onChange={handleChangeAim}
	      	notFoundContent="цели не найдены"
	      	onSelect={onSelectHandler}
	      	open={open['aims']}
          	disabled={props.disabled}
	      	onDropdownVisibleChange={(visible) => setOpen({'protectedObjects': false, 'pesticideTypes': false, 'aims': visible, 'brands': false})}   
	    	options={aimsData}
		/>

	    <label><h4>Бренды</h4></label>
	    <Select
	      	mode="multiple"
	      	showArrow
	      	allowClear
	      	style={{
	        	width: '100%',
	        	marginTop: 5,
	        	marginBottom: 20,
	      	}}
	      	placeholder="Выберите бренды"
	      	onChange={handleChangeBrand}
	      	notFoundContent="бренды не найдены"
	      	onSelect={onSelectHandler}
	      	open={open['brands']}
          	disabled={props.disabled}
	      	onDropdownVisibleChange={(visible) => setOpen({'protectedObjects': false, 'pesticideTypes': false, 'aims': false, 'brands': visible})}
	    	options={brandsData}
		/>

	    {/*<label>Механизмы действия д.в.</label>
	    	    <Select
	    	      mode="multiple"
	    	      showArrow
	    	      allowClear
	    	      style={{
	    	        width: '100%',
	    	        marginTop: 5,
	    	        marginBottom: 20,
	    	      }}
	    	      placeholder="Выберите сочетания"
	    	      onChange={handleChangeMOACombinations}
	    	    >
	    	    	{moaCombinationsData.map(item => <Option label={item.label} value={item.value}/>)}
	    	    </Select>

	    <label>Химические классы д.в.</label>
	    <Select
	      mode="multiple"
	      showArrow
	      allowClear
	      style={{
	        width: '100%',
	        marginTop: 5,
	        marginBottom: 20,
	      }}
	      placeholder="Выберите сочетания"
	      onChange={handleChangeChemicalClassesCombinations}
	    >
	    	{chemicalClassesCombinationsData.map(item => <Option label={item.label} value={item.value}/>)}
	    </Select>

	    <label>Действующие вещества</label>
	    <Select
	      mode="multiple"
	      showArrow
	      allowClear
	      style={{
	        width: '100%',
	        marginTop: 5,
	        marginBottom: 20,
	      }}
	      placeholder="Выберите сочетания"
	      onChange={handleChangeComponentsCombinations}
	    >
	    	{componentsCombinationsData.map(item => <Option label={item.label} value={item.value}/>)}
	    </Select>*/}
    </div>
  );
};