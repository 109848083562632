import {SettingOutlined, MenuFoldOutlined, MenuUnfoldOutlined, MenuOutlined} from '@ant-design/icons'
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import { Grid, Button, Drawer, Divider } from 'antd'
import { LogInLogOutButton } from '../loginLogout/loginLogoutButton'
import { useAuth } from '../../auth'

import './menu.css'
const { useBreakpoint } = Grid

export function MainMenu() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const screens = useBreakpoint();

  const authContext = useAuth();
  return (
    <>
      <ul className="main-menu">

        <li className="logo">
          АГРОНОМИУМ
        </li>
        {screens["lg"] && 
          <>
            <li>
              <NavLink exact to="/">Каталог</NavLink>
            </li>
            <li>
              <NavLink exact to="/product-portfolio">Продуктовые портфели</NavLink>
            </li>
            <li>
              <NavLink exact to="/find-analogs">Аналоги</NavLink>
            </li>
            <li>
              <NavLink exact to="/classification">Классификация</NavLink>
            </li>

            {/* {authContext.isAutorised() && 
            <li>
              <NavLink exact to="/favorites">Избранное</NavLink>
            </li>} */}
            
            <li className="right-top">
              <LogInLogOutButton />
            </li>
          </>
        }

          {!screens["lg"] &&
            <li className="right-top">
              <Button className="drawer-menu-button" type="text" size="large" icon={<MenuOutlined />}  onClick={showDrawer}/>
            </li>
          }
      </ul>

      <Drawer title="Agronomium.ru" placement={!screens["xs"] ? "right" : "top"} onClose={onClose} open={open}>
        <ul className="drawer-main-menu">
          <li onClick={onClose}><NavLink exact to="/">Каталог</NavLink></li>
          <li onClick={onClose}><NavLink exact to="/product-portfolio">Продуктовые портфели</NavLink></li>
          <li onClick={onClose}><NavLink exact to="/find-analogs">Аналоги</NavLink></li>
          <Divider/>
          {authContext.isAutorised() && 
            <li className="drawer-main-menu-user-block">{authContext.user}</li>
          }
          <li className="drawer-main-menu-user-block" onClick={onClose}>
            <LogInLogOutButton type="primary" />
          </li>

        </ul>
      </Drawer>
    </>
  )
}
