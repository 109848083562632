import { useHistory, useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { LockOutlined, UserOutlined, MailOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Alert, Button, Input } from 'antd';

import { useAuth } from '../auth'

import { Loader } from '../components/loader/loader'

export default function LoginPage () {
  let history = useHistory()
  let location = useLocation()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  let { from } = location.state || { from: { pathname: '/' } }
  const auth = useAuth()

  useEffect(() => {
    if (auth.isAutorised()) {
      history.replace(from)
    }
  })
  let login = (e) => {
    e.preventDefault()
    setLoading(true)
    auth.login(username, password).then(() => {
      history.replace(from)
      setLoading(false)
    }).catch(e => {
      setError('Вы ввели некорректный логин и/или пароль')
      setLoading(false)
    })
    return false
  }

  return (
    <div className="custom-login-form">
      { /* Here is from path: */}
      <form onSubmit={login}>
        <h1>Введите логин и пароль</h1>
        <Input 
          prefix={<UserOutlined className="site-form-item-icon" />} 
          placeholder="Логин"
          className="custom-login-form-input"
          onChange={(e) => {
            setUsername(e.target.value)
          }}
        />
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          className="custom-login-form-input"
          placeholder="Пароль"
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
        />
        {error && <Alert
              message={error}
              description="Проверьте данные и попробуйте ещё раз"
              type="error"
              showIcon
              className="custom-login-form-alert"
        />}
        {!loading ? 
            <Button type="primary" htmlType="submit" block className="custom-login-form-button">Войти</Button>
        : <Button type="primary" htmlType="submit" block loading className="custom-login-form-button">Авторизуемся</Button>
        }
        <div>
            <Button size="large" key="linkToMail" block icon={<MailOutlined/>} href="mailto:info@agronomium.ru?subject=Запрос логина и пароля">
              Запросить доступ
            </Button>
        </div>
      </form>
    </div>
  )
}