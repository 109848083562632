import React, { useMemo, useState } from 'react';

import 'antd/dist/antd.css';
import './searchableTree.css';
import { Input, Tree } from 'antd';

const { DirectoryTree } = Tree;

const { Search } = Input;
const treeMoaCombinations = require("../../data/treeMoaCombinations.json");
const treeFRAC = require("../../data/treeFRAC.json");
const treeHRAC = require("../../data/treeHRAC.json");
const treeIRAC = require("../../data/treeIRAC.json");
const dataSrc = [treeMoaCombinations, treeFRAC, treeHRAC, treeIRAC];
const dataStr = ["treeMoaCombinations", "treeFRAC", "treeHRAC", "treeIRAC"];

const dataList = [];

const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key } = node;
    dataList.push({
      key,
      title: key,
    });

    if (node.children) {
      generateList(node.children);
    }
  }
};

const getParentKey = (key, tree) => {
  let parentKey;

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }

  return parentKey;
};

export function SearchableTree(props) {
  const myTreeData = props.data;
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const onChange = (e) => {
    const value = e.target.value?.toLowerCase();
    if(value.length >= 3){
      const newExpandedKeys = dataList
        .map((item) => {
          if (item.title.toLowerCase().indexOf(value) > -1) {
            return getParentKey(item.key, myTreeData);
          }

          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      setExpandedKeys(newExpandedKeys);
      setSearchValue(value);
      setAutoExpandParent(true);
    } else {
      setExpandedKeys([]);
    }
  };

  const treeData = useMemo(() => {
      
    const loop = (data) =>
      data.map((item) => {
        const strTitle = item.title;
        const index = strTitle.toLowerCase().indexOf(searchValue.toLowerCase());
        const beforeStr = strTitle.substring(0, index);
        const str = strTitle.substring(index, index + searchValue.length);
        const afterStr = strTitle.slice(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{str}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );

        if (item.children) {
          return {
            title,
            key: item.key,
            icon: item.icon,
            children: loop(item.children),
          };
        }

        return {
          title,
          key: item.key,
          icon: item.icon,
        };
      });

    return loop(myTreeData);
  }, [searchValue]);

  generateList(props.data);

  return (
    <div>
      <Search
        style={{
          marginBottom: 8,
        }}
        allowClear
        placeholder="Поиск"
        onChange={onChange}
      />
      <DirectoryTree
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        treeData={treeData}
      />
    </div>
  );
};
