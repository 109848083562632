import React, { useEffect, useState } from 'react';
import Directual from 'directual-api';
import { useAuth } from '../../auth'
import { Loader } from '../loader/loader';

import './table.module.css'


// Getting data from Directual

// Connect to Directual api
const api = new Directual({ apiHost: '/' })
//const api = new Directual({apiHost: 'https://api.directual.com', appID: "b18cea8d-c38a-40e2-9099-bae198b2372e"});

export function Table (props) {

  // API-endpoint details
  const dataStructure = props.dataStructure // todo: write here sysname of your data structure
  const endpoint = props.endpoint // todo: write here Method name of your API-endpoint

  // connect authentication context
  const auth = useAuth();

  // Hooks for handling state
  const [payload, setPayload] = useState([]); // API response
  const [pageInfo, setPageInfo] = useState({}); // API response metadata, e.g. number of objects
  const [loading, setLoading] = useState(true); // initial loader
  const [badRequest, setBadRequest] = useState(); // API error message
  const [pageLoading, setPageLoading] = useState(false); // paging loader
  const [pageNum, setPageNum] = useState(props.pageNum || 0); // Page number, by default = 0
  const [pageSize, setPageSize] = useState(props.pageSize || 10); // Page size, bu default = 10

  // Paging
  useEffect(() => {
    setPageLoading(true)
    getData()
  }, [pageNum])

  const nextPage = () => {
    setPageLoading(true)
    setPageNum(pageNum + 1)
  }
  const prevPage = () => {
    setPageLoading(true)
    setPageNum(pageNum - 1)
  }

  // GET-request
  function getData() {
    console.log('Отправлен запрос в компоненте table...')
    api

      // Data structure
      .structure(props.dataStructure)
      // GET request + query params (sessionID, page, pageSize by default)
      .getData(props.endpoint, { sessionID: auth.sessionID, page: pageNum, pageSize: pageSize })
      // other possible query params:
      // {{HttpRequest}} — any param for Filtering
      // sort=FIELD_SYSNAME_1,desc,FIELD_SYSNAME_2,asc — sorting with multiple params
      .then((response) => {
        setPayload(response.payload)
        setPageInfo(response.pageInfo)
        setLoading(false)
        setPageLoading(false)
      })
      .catch((e) => {
        // handling errors
        setLoading(false)
        setPageLoading(false)
        console.log(e.response)
        setBadRequest(e.response.status + ', ' + e.response.data.msg)
      })
  }
return(
  <div>
    {loading && <Loader />}
      {payload && !loading &&
        <div>
          <table className={props.dataStructure == 'pesticides_standart' && 'catalog_table'} 
          className={props.dataStructure == 'pesticide_analogs' && 'find_analogs_table'}>
            <thead>
                {props.dataStructure == 'pesticides_standart' && 
                  <tr>
                    <td>Наименование</td>
                    <td>Формуляция</td>
                    <td>Защищаемые объекты</td>
                    <td>Цели обработки</td>
                  </tr>
                }

                {props.dataStructure == 'pesticide_analogs' && 
                  <tr>
                    <td>Наименование</td>
                    <td>Формуляция</td>
                    <td>Полные аналоги</td>
                    <td>Аналоги по сочетанию действующих веществ</td>
                    <td>Аналоги по сочетанию химических классов</td>
                    <td>Аналоги по сочетанию механизмов действия</td>
                  </tr>
                }
            </thead>
            <tbody>
              {payload.map(row => 
                <tr>
                  <td><p>{row.name}</p></td>
                  <td><p>{row.formulation_source}</p></td>

                  {props.dataStructure == 'pesticides_standart' &&
                    <td>{row.protected_objects_id_list.map(item =>
                      <p>{item.name}</p>
                      )}
                    </td>
                  }
                  {props.dataStructure == 'pesticides_standart' &&
                    <td>{row.aims_id_list.map(item =>
                      <p>{item.name}</p>
                      )}
                    </td>
                  }


                  {props.dataStructure == 'pesticide_analogs' &&
                    <td>{row.analogs_by_formulation.map(item =>
                      <p>{item.name_standart}</p>
                      )}
                    </td>
                  }
                  {props.dataStructure == 'pesticide_analogs' &&
                    <td>{row.analogs_by_poisons_combination.map(item =>
                      <p>{item.name_standart}</p>
                      )}
                    </td>
                  }
                  {props.dataStructure == 'pesticide_analogs' &&
                    <td>{row.analogs_by_chemical_classes_combination.map(item =>
                      <p>{item.name_standart}</p>
                      )}
                    </td>
                  }
                  {props.dataStructure == 'pesticide_analogs' &&
                    <td>{row.analogs_by_mode_of_actions_combination.map(item =>
                      <p>{item.name_standart}</p>
                      )}
                    </td>
                  }
                </tr>
              )}
            </tbody>
          </table>

          {/* API response */}
          {/*<div className="request-info">
            <span>Структура данных: <b>{dataStructure ? dataStructure : <span className="error">not provided</span>}</b></span>
            <span>API-endpoint: <b>{endpoint ? endpoint : <span className="error">not provided</span>}</b></span>
            <span>Payload: <code>{JSON.stringify(payload)}</code></span>
            <span>Payload info: <code>{JSON.stringify(pageInfo)}</code></span>
            {badRequest && <code className="error">Error: <b>{badRequest}</b></code>}
          </div>*/}

          {/* Paging */}
          {pageLoading && <Loader />}
          {!pageLoading &&
            <div>
              <button disabled={(pageNum <= 0) && "disabled"} onClick={prevPage}>пред.</button>
              <button disabled={(badRequest || (pageNum >= pageInfo.totalPage - 1)) && "disabled"} onClick={nextPage}>след.</button>
            </div>
          }

        </div>}
  </div>
)
      
}