import React, { useEffect, useState } from 'react';
import { Input, Grid  } from 'antd';
import './searchBlock.css'

const { Search } = Input;
const { useBreakpoint } = Grid;

export function SearchBlock(props) {
    console.log(props)
    const screens = useBreakpoint();

    return (
        <div className="search-block-wrapper">
            <Search
              placeholder="Искать препараты на Agronomium.ru"
              allowClear
              enterButton="Найти"
              size="large"
              onSearch={props.onSearch}
              disabled={props.disabled}
            />         
        </div>
    )
}