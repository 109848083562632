import React from 'react'
import './labelMoaGroup.css'

const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function sortLabels(a, b){
  let correctLabelsOrder = ['0','1','1A','1B','2','2A','2B','3','3A','3B','4','4A','4B','4C','4D','4E','4F','5','5F','6','6F','7','7A','7B','7C','8','8A','8B','8C','8D','8E','8F','9','9B','9D','10','10A','10B','11','11A','11B','12','12A','12B','12C','12D','13','13D','14','14D','15','15D','16.1','16.2','16.3','16D','17','17D','18','18D','19','19D','20','20A','20B','20C','20D','21','21A','21B','22','22A','22B','23','23B','24','24A','24B','25','25A','25B','27','28','28B','29','29B','30','30B','31','31B','32','32B','33','33B','34','34B','35','36','37','38','39','40','41','43','45','47','48','49','50','BM01','BM02','M01','M02','M03','M04','M05','M06','M07','M08','M09','M10','M11','M12','NC','P01','P02','P03','P04','P05','P06','P07','P08','U06','U12','U13','U14','U16','U17','U18','UN','UNB','UNE','UNF','UNM','UNP','UNV']
 
  return correctLabelsOrder.indexOf(a) - correctLabelsOrder.indexOf(b);
}

const sortedUniq = arr => [...new Set(arr)].sort(sortLabels)

const committeesData = [
    {acronym: "HRAC", engSingleName: "HERBICIDE", engMultipleName: "HERBICIDES", rusSingleName: "ГЕРБИЦИД", rusMultipleName: "ГЕРБИЦИДЫ", rusSingleHead: "ГРУППА", engSingleHead: "GROUP", rusMultipleHead: "ГРУППЫ", engMultipleHead: "GROUP"},
    {acronym: "FRAC", engSingleName: "FUNGCIDE", engMultipleName: "FUNGICIDES", rusSingleName: "ФУНГИЦИД", rusMultipleName: "ФУНГИЦИДЫ", rusSingleHead: "ГРУППА", engSingleHead: "GROUP", rusMultipleHead: "ГРУППЫ", engMultipleHead: "GROUP"},
    {acronym: "IRAC", engSingleName: "INSECTICIDE", engMultipleName: "INSECTICIDES", rusSingleName: "ИНСЕКТИЦИД", rusMultipleName: "ИНСЕКТИЦИДЫ", rusSingleHead: "ГРУППА", engSingleHead: "GROUP", rusMultipleHead: "ГРУППЫ", engMultipleHead: "GROUP"},
    {acronym: "NRAC", engSingleName: "NEMATICIDE", engMultipleName: "NEMATICIDES", rusSingleName: "НЕМАТИЦИД", rusMultipleName: "НЕМАТИЦИДЫ", rusSingleHead: "ГРУППА", engSingleHead: "GROUP", rusMultipleHead: "ГРУППЫ", engMultipleHead: "GROUP"},
]

export function LabelMoaGroup(props) {
    if (props.type == "multiple") {
        let labelsGroupedByCommittee = groupBy(props.data, 'committee');
        let committeeWithlabels = [];
        let curLabels = []
        Object.keys(labelsGroupedByCommittee).map(groupedByCommittee =>
            {if(groupedByCommittee !== 'undefined'){
                curLabels = []
                labelsGroupedByCommittee[groupedByCommittee].map(item => 
                    curLabels.push(item.label)
                )
                curLabels = sortedUniq(curLabels)
                committeeWithlabels[groupedByCommittee] = {"labels": curLabels}
            } else{}}
        )
        

        {/*console.log(committeeWithlabels);*/}

        return (
            <div className="pesticide-MOA-group-label-wrapper">
                {committeesData.map(committee =>
                    Object.keys(labelsGroupedByCommittee).includes(committee.acronym) &&
                        <span className="pesticide-MOA-group-label-block">
                            <span className="pesticide-MOA-text">{committeeWithlabels[committee.acronym].labels.length == 1 ? committee.rusSingleHead : committee.rusMultipleHead}</span>
                            {committeeWithlabels[committee.acronym].labels.map(item => 
                                <span className="pesticide-MOA-label">{item}</span>
                            )}
                            <span className="pesticide-MOA-text">{committeeWithlabels[committee.acronym].labels.length == 1 ? committee.rusSingleName : committee.rusMultipleName}</span>
                        </span>
                )}          
            </div>
        )
    } else {
        console.log(props.data.commitee)
        return (
            <div className="pesticide-MOA-group-label-wrapper">
                {committeesData.map(committee =>
                    props.data.committee == committee.acronym &&
                    <span className="pesticide-MOA-group-label-block">
                        <span className="pesticide-MOA-text">{committee.rusSingleHead}</span>
                        <span className="pesticide-MOA-label">{props.data.label}</span>
                        <span className="pesticide-MOA-text">{committee.rusSingleName}</span>
                    </span>
                )}
                {(props.data.committee != 'HRAC' && props.data.committee != 'FRAC' && props.data.committee != 'IRAC'&& props.data.committee != 'NRAC') &&
                    <span className="pesticide-MOA-label">компонент без лейбла</span>
                }
            </div>
        )
        
    }
    
}