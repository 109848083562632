import React from 'react';

import './labelsList.css';
import { Tooltip } from 'antd';


export function LabelsList(props) {
  //console.log(props)
  const labelsData = []
  {(props.type == "color" || props.type == "color_acronym") &&
    props.data.map(item => {
      let curLabel = {}
      let curClassName = props.type == "color" ? "custom-label" : "custom-acronym-label";
      curLabel["name"] = item.name
      curLabel["borderColor"] = item["border_color"]
      curLabel["acronym_background_color"] = item["acronym_background_color"]
      curLabel["acronym_border_color"] = item["acronym_border_color"]
      curLabel["className"] = (item.shape == 'square') ? curClassName+" custom-square-label" 
            : (item.shape == 'circle') ? curClassName+" custom-circle-label" 
            : (item.shape == 'rectangular') ? curClassName+" custom-rectangular-label" 
            : curClassName
      curLabel["acronym"] = JSON.parse(item.acronym)
      
      curLabel["borderWidth"] = item["border_thickness"]
      curLabel["defaultBackgroundColor"] = (item.color.indexOf(', ') == -1) ? item.color : item["border_color"]
      curLabel["gradientBackground"] = (item.color.indexOf(', ') == -1 ) ? "linear-gradient(45deg, "+item.color+" 50%, "+item.color+" 50%)" : "linear-gradient(45deg, "+item.color.slice(0, item.color.indexOf(', '))+" 50%, "+item.color.slice(item.color.indexOf(', ') + 2, item.color.length)+" 50%)"
      labelsData.push(curLabel)
    })
  }
  
  return (
    <>
      {(props.type == "color" || props.type == "color_acronym") && 
        labelsData.map(label =>
          <Tooltip title={label["name"]} placement="top" >
            {props.type == "color" && 
              <div className={label["className"]}
                style={{
                  "backgroundColor": label["defaultBackgroundColor"],
                  "background": label["gradientBackground"],
                  "borderColor": label["borderColor"],
                  "borderWidth": label["borderWidth"],
                }}
              >
              </div>}
            {props.type == "color_acronym" && 
              <div className={label["className"]} 
                style={{
                  "backgroundColor": label["acronym_background_color"],
                  "borderColor": label["acronym_border_color"],
                  "borderWidth": 'thin',
                }}
              >
              {label["acronym"].map(acronym => 
                <span className="label-letter" style={{
                  "color": acronym.color,
                }}>{acronym.letter}</span>
              )}
              </div>}
          </Tooltip>
        )
      }
      {props.type == "brand_acronym" &&
        props.data.map(brand => 
          <span className = "custom-brand-acronym-label" style={{color: brand.color}}>{brand.acronym} </span>
        )
      }
    </>
  );
};
