import React from 'react'
import { Col, Row, Divider } from 'antd';
import './reglamentsPanelHeader.css'

export function ReglamentsPanelHeader(props) {
    console.log(props.data);
    return (
        <div className="reglaments-panel-header-wrapper">
            <Row className="reglaments-panel-header">
                <Col span="19">
                    <h3>{props.data.protectedObject}</h3>
                </Col>
                <Col span="19">
                    <p>{props.data.aim}</p>
                </Col>
                <Col span="4" offset="1">
                    <p>{props.data.norm}</p>
                </Col>
            </Row>
        </div>
    )
}