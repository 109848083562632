import { FilterOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { PageHeader} from 'antd';
import { Grid, Col, Row, Collapse } from 'antd';

import { Table } from '../components/table/table';
import { GridCards } from '../components/gridCards/gridCards';
import { FiltersBlock } from '../components/filtersBlock/filtersBlock';
import { SearchBlock } from '../components/searchBlock/searchBlock';

const { useBreakpoint } = Grid;
const { Panel } = Collapse;

export default function Catalog() {

  const screens = useBreakpoint();

  const [protectedObjectsValues, setProtectedObjectsValues] = useState([]);
  const [protectedObjectsIndexes, setProtectedObjectsIndexes] = useState('');
  const [pesticideTypesValues, setPesticideTypesValues] = useState([]);
  const [pesticideTypesIndexes, setPesticideTypesIndexes] = useState('');
  const [aimsValues, setAimsValues] = useState([]);
  const [aimsIndexes, setAimsIndexes] = useState('');
  const [brandsValues, setBrandsValues] = useState([]);
  const [brandsIndexes, setBrandsIndexes] = useState('');

  const [moaCombinationsValues, setMoaCombinationsValues] = useState([]);
  const [moaCombinationsIndexes, setMoaCombinationsIndexes] = useState('');
  const [chemicalClassesCombinationsValues, setChemicalClassesCombinationsValues] = useState([]);
  const [chemicalClassesCombinationsIndexes, setChemicalClassesCombinationsIndexes] = useState('');
  const [componentsCombinationsValues, setComponentsCombinationsValues] = useState([]);
  const [componentsCombinationsIndexes, setComponentsCombinationsIndexes] = useState('');

  const [searchBlockValue, setSearchBlockValue] = useState('');
  const [currentEndpoint, setCurrentEndpoint] = useState('get_all');
  const [selectIsDisabled, setSelectIsDisabled] = useState(false)

  const handleFiltersChange = (event) => {
    setCurrentEndpoint('get_all')
    setSearchBlockValue('')
    setSelectIsDisabled(true)
    switch (event.selectName)
    { 
      case "protectedObjects":
        setProtectedObjectsValues(event.labels)
        setProtectedObjectsIndexes(event.keys)
        break;
      case "pesticideTypes":
        setPesticideTypesValues(event.labels)
        setPesticideTypesIndexes(event.keys)
        break;
      case "brands":
        setBrandsValues(event.labels)
        setBrandsIndexes(event.keys)
        break;
      case "aims":
        setAimsValues(event.labels)
        setAimsIndexes(event.keys)
        break;
      /*case "moaCombinations":
        setMoaCombinationsValues(event.values)
        setMoaCombinationsIndexes(event.labels)
        break;
      case "chemicalClassesCombinations":
        setChemicalClassesCombinationsValues(event.values)
        setChemicalClassesCombinationsIndexes(event.labels)
        break;
      case "componentsCombinations":
        setComponentsCombinationsValues(event.values)
        setComponentsCombinationsIndexes(event.labels)
        break;*/
      default:
        alert('Default case');
    }
    console.log(`Вызван handleFiltersChange в Catalog`)
  }

  const handleSearchBlockChange = (event) => {
    console.log(event)
    setSelectIsDisabled(true)
    setCurrentEndpoint('search_all')
    setSearchBlockValue(event)
    this.blur()
    console.log(`Вызван handleSearchBlockChange в Catalog`)
  }

  const handleOnDataLoaded = (event) => {
    setSelectIsDisabled(false)
  }

  return (
    <div className = "catalog-page-wrapper">

      {!screens["lg"] && <h1 className="custom-page-header">Справочник пестицидов РФ</h1>}

      <Row gutter={!screens["xxl"] ? 15:25, !screens["xxl"] ? 15:25}>
        <Col xs={0} sm={0} md={8} lg={6} xl={6} xxl={{span: 20, order: 2}}>
            {!screens["xxl"] && <h2>Фильтры</h2>}
        </Col>
        <Col xs={24} sm={24} md={16} lg={18} xl={18} xxl={{span: 4, order: 1}}>
          <SearchBlock onSearch = {handleSearchBlockChange} disabled = {selectIsDisabled}/>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={{span: 4, order: 3}}>
          <div className='catalog-page-filters-block'>
            {screens["md"] ?
              <FiltersBlock onChange = {handleFiltersChange} disabled = {selectIsDisabled}/>
            :
              <Collapse className="catalog-page-filters-panel">
                <Panel header="Фильтры" extra={<FilterOutlined />}>
                  <FiltersBlock onChange = {handleFiltersChange} disabled = {selectIsDisabled}/>
                </Panel>
              </Collapse>
            }

          </div>
        </Col>
        <Col xs={24} sm={24} md={16} lg={18} xl={18} xxl={{span: 20, order: 4}}>
          <div className='catalog-page-results-block'>
            {/*<h2>Список пестицидов</h2>*/}
            <GridCards 
              dataStructure = 'pesticides_standart' 
              endpoint = {currentEndpoint} 
              pageNum = '0' 
              pageSize = '12' 
              protectedObjects = {protectedObjectsIndexes} 
              protectedObjectsVal = {protectedObjectsValues} 
              pesticideTypes = {pesticideTypesIndexes} 
              pesticideTypesVal = {pesticideTypesValues} 
              aims = {aimsIndexes} 
              aimsVal = {aimsValues} 
              brands = {brandsIndexes} 
              brandsVal = {brandsValues} 
              moaCombinations = {moaCombinationsIndexes} 
              moaCombinationsVal = {moaCombinationsValues}
              chemicalClassesCombinations = {chemicalClassesCombinationsIndexes} 
              chemicalClassesCombinationsVal = {chemicalClassesCombinationsValues}
              componentsCombinations = {componentsCombinationsIndexes} 
              componentsCombinationsVal = {componentsCombinationsValues} 
              searchBlockVal = {searchBlockValue}
              onDataLoaded = {handleOnDataLoaded}/>
          </div>
        </Col>
      </Row>
    </div>
  )
}