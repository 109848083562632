import React, { useEffect, useState } from 'react';
import Directual from 'directual-api';
import { useAuth } from '../../auth'
import { Loader } from '../loader/loader';
import { InfoList } from '../infoList/infoList';
import { LabelsList } from '../labelsList/labelsList';
import { LabelMoaGroup } from '../labelMoaGroup/labelMoaGroup';
import { ReglamentsPanelHeader } from '../reglamentsPanelHeader/reglamentsPanelHeader';
import { TagsEllipsisText } from '../tagsEllipsisText/tagsEllipsisText';


import { Card, Grid, Col, Row, Tag, Pagination, Modal, Table, Button, Tabs, Empty, Result, Popover, Collapse, Typography } from 'antd';

import './gridCards.css'

// Getting data from Directual
// Connect to Directual api
// const api = new Directual({ apiHost: '/' });
const api = new Directual({apiHost: 'https://api.directual.com', appID: "d1e73296-0212-4ac1-8943-bfe9bceaa22d"});

//Antd Meta для карточек
const { Meta } = Card;
const { Text } = Typography;
const { useBreakpoint } = Grid;
const { Panel } = Collapse;

const columnsReglamentsTable = [
  {
    title: 'Культура, обрабатываемый объект',
    dataIndex: 'protectedObject',
    key: 'protectedObject',
  },
  {
    title: 'Вредный объект',
    dataIndex: 'aim',
    key: 'aim',
  },
  {
    title: 'Норма применения препарата (л/га, кг/га, л/т, кг/т)',
    dataIndex: 'norm',
    key: 'norm',
  },
  {
    title: 'Способ, время обработки, особенности применения',
    dataIndex: 'fullAdditional',
    key: 'fullAdditional',
    responsive: ['lg'],
  },
  {
    title: 'Срок ожидания (кратность обработок)',
    dataIndex: 'workSum',
    key: 'workSum',
    responsive: ['lg'],
  },
  {
    title: 'Сроки выхода для ручных (механизированных) работ',
    dataIndex: 'workTime',
    key: 'workTime',
    responsive: ['lg'],
  },

];
const columnsCompoundTable = [
  {
    title: 'Компонент',
    dataIndex: 'componentOriginal',
    key: 'componentOriginal',
  },
  {
    title: 'Химический класс',
    dataIndex: 'chemicalClassGlobalWithCode',
    key: 'chemicalClassGlobalWithCode',
  },
  {
    title: 'Механизм действия',
    dataIndex: 'modeOfActionWithCode',
    key: 'modeOfActionWithCode',
  },
  {
    title: 'Лейбл',
    dataIndex: 'modeOfActionLabel',
    key: 'modeOfActionLabel',
    render: (value) => <LabelMoaGroup type="single" data={value}/>,
    responsive: ['lg'],
  },
  {
    title: 'Содержание',
    dataIndex: 'amountWithUnitMeasurement',
    key: 'amountWithUnitMeasurement',
    responsive: ['lg'],
  },
];
let dataCompoundTable = [];
let dataReglamentsTable = [];
let dataPesticideInfo = [
  {
    title: "Бренды:",
    key: "brands_id_list",
    value: [],
    type: "tags",
    tagKey: "name",
  },{
    title: "Формуляция:",
    key: "formulation_source",
    value: "",
    type: "text",
  }/*,{
    title: "Сочетание химических классов",
    key: "chemical_classes_source",
    value: "",
    type: "text",
  },{
    title: "Сочетание химических классов с кодом международной классификации",
    key: "chemical_classes_standart",
    value: "",
    type: "text",
  },{
    title: "Сочетание механизмов действия с кодом международной классификации",
    key: "mode_of_actions_standart",
    value: "",
    type: "text",
  }*/,{
    title: "Препаративная форма:",
    key: "substance",
    value: "",
    type: "text",
  },{
    title: "Типы пестицидов:",
    key: "pesticide_types_standart_id_list",
    value: [],
    type: "tags",
    tagKey: "name",
  },{
    title: "Класс опасности для пчел:",
    key: "danger_class_bee",
    value: [],
    type: "text",
  },{
    title: "Класс опасности для человека:",
    key: "danger_class_hum",
    value: [],
    type: "text",
  },{
    title: "Защищаемые объекты:",
    key: "protected_objects_id_list",
    value: [],
    type: "tags",
    tagKey: "name",
  },{
    title: "Цели обработки:",
    key: "aims_id_list",
    value: [],
    type: "tags",
    tagKey: "name",
  },       
];
let dataPesticideRegistrationInfo = [
  {
    title: "Наименование полное:",
    key: "name_source",
    value: "",
    type: "text",
  },
  {
    title: "Номер государственной регистрации:",
    key: "registration_num",
    value: "",
    type: "text",
  },
  {
    title: "Регистранты:",
    key: "registrants",
    value: "",
    type: "text",
  },
  {
    title: "Дата окончания регистрации:",
    key: "registration_date_off",
    value: "",
    type: "date",
  },
];
export function GridCards (props) {

  console.log(`вызов GridCards`);
  const screens = useBreakpoint();
  const screensList = ['xxl','xl','lg','md','sm','xs']

  let tagMaxWidth = window.innerWidth * (screens["md"] ? 0.5 : 0.85);


  // API-endpoint details
  const dataStructure = props.dataStructure // todo: write here sysname of your data structure
  const endpoint = props.endpoint // todo: write here Method name of your API-endpoint

  // connect authentication context
  const auth = useAuth();

  // Hooks for handling state
  const [payload, setPayload] = useState([]); // API response
  const [pageInfo, setPageInfo] = useState({}); // API response metadata, e.g. number of objects
  const [loading, setLoading] = useState(true); // initial loader
  const [badRequest, setBadRequest] = useState(); // API error message
  const [pageLoading, setPageLoading] = useState(false); // paging loader
  const [pageNum, setPageNum] = useState(props.pageNum || 0); // Page number, by default = 0
  const [pageSize, setPageSize] = useState(props.pageSize || 10); // Page size, bу default = 10

  //Фильтры
  const [pesticideTypes, setPesticideTypes] = useState(props.pesticideTypes); // Список идентификаторов типов пестицидов
  const [protectedObjects, setProtectedObjects] = useState(props.protectedObjects); // Список идентификаторов защищаемых объектов
  const [aims, setAims] = useState(props.aims); // Список идентификаторов целей обработки
  const [brands, setBrands] = useState(props.brands); // Список идентификаторов брендов

  const [openModalForCard, setOpenModalForCard] = useState(false);
  const [clickedCardData, setClickedCardData] = useState({}); // данные карточки по которой сделан клик
  const handleCancel = () => {
    setClickedCardData({});
    setOpenModalForCard(false);
  };
  const showModalForCard = (data) => {
    dataReglamentsTable = [];
    dataCompoundTable = [];

    dataPesticideInfo.map((item, indx) => {
      console.log(data);
      if (item.type == "text") {
        dataPesticideInfo[indx].value = data[item.key];
      } else if (item.type == "tags") {
        dataPesticideInfo[indx].value = [];
        data[item.key].map((tag) => {
          dataPesticideInfo[indx].value.push(tag[item.tagKey]);
        });
      } else {
        dataPesticideInfo[indx].value = "";
      }
    });

    dataPesticideRegistrationInfo.map((item, indx) => {
      if (item.type == "text") {
        dataPesticideRegistrationInfo[indx].value = data[item.key];
      } else if (item.type == "date")  {
        let date = new Date(data[item.key]);
        let curDate = (date.getDate() < 10) ? "0"+date.getDate() : date.getDate();
        let curMonth = date.getMonth() + 1;
        curMonth = (curMonth < 10) ? "0" + curMonth : curMonth;
        dataPesticideRegistrationInfo[indx].value = curDate+"."+curMonth+"."+date.getFullYear();
      } else {
        dataPesticideRegistrationInfo[indx].value = "";
      }
    });

    // console.log(dataPesticideInfo);
    data.regulations_source_id_list.map((row, indx) => {
      dataReglamentsTable.push({
        key: indx, 
        protectedObject: data.regulations_source_id_list[indx]["protected_object"], 
        aim: data.regulations_source_id_list[indx]["aim"], 
        norm: data.regulations_source_id_list[indx]["norm"], 
        fullAdditional: data.regulations_source_id_list[indx]["full_additional"],
        workSum: data.regulations_source_id_list[indx]["work_sum"], 
        workTime: data.regulations_source_id_list[indx]["work_time"],
      })
    });

    let componentsNames = [];
    data.components_id_list.map((row, indx) => {
      if (!componentsNames.includes(data.components_id_list[indx]["component_original"])) {
        componentsNames.push(data.components_id_list[indx]["component_original"]);
        dataCompoundTable.push({
          key: indx,
          componentOriginal: data.components_id_list[indx]["component_original"],
          chemicalClassGlobalWithCode: data.components_id_list[indx]["chemical_class_global_with_code"],
          modeOfActionWithCode: data.components_id_list[indx]["mode_of_action_with_code"],
          amountWithUnitMeasurement: data.components_id_list[indx]["amount_with_unit_measurement"],
          modeOfActionLabel: data.components_id_list[indx],
        })
      } else {}
      
    });

    
    setClickedCardData(data);
    setOpenModalForCard(true);
  };
  
  // Paging

  useEffect(() => {
    setPageLoading(true)
    getData()
  }, [pageNum])

  useEffect(() => {
  	if (pageNum == 0){
  		console.log("Просто загружаем фильтры")
	    setPageLoading(true)
	    getData()
  	} else {
  		console.log("Обновляем номер страницы на 0")
    	setPageNum(0)
  	}
  }, [props.protectedObjects, props.pesticideTypes, props.aims, props.brands, props.moaCombinations, props.chemicalClassesCombinations, props.componentsCombinations, props.searchBlockVal])

  const nextPage = () => {
    setPageLoading(true)
    setPageNum(pageNum + 1)
  }
  const prevPage = () => {
    setPageLoading(true)
    setPageNum(pageNum - 1)
  }

  const onChangePagination = (page, pageSize) => {
  	console.log(`Измени страницу на ${page}`)
  	setPageLoading(true)
    setPageNum(Number(page) - 1)
  }

  const ellipsis = true;


  // GET-request
  function getData() {
    console.log('Отправлен запрос...')
    api

      // Data structure
      .structure(props.dataStructure)
      // GET request + query params (sessionID, page, pageSize by default)
      .getData(props.endpoint, { 
      	sessionID: auth.sessionID, 
      	page: pageNum, 
      	pageSize: pageSize, 
      	protectedObjects: props.protectedObjects, 
      	pesticideTypes: props.pesticideTypes, 
      	aims: props.aims, 
      	brands: props.brands, 
      	moaCombinations: props.moaCombinations, 
      	chemicalClassesCombinations: props.chemicalClassesCombinations, 
      	componentsCombinations: props.componentsCombinations,
        searchBlockValue: props.searchBlockVal })
      // other possible query params:
      // {{HttpRequest}} — any param for Filtering //, name: "Пришанс, СЭ"
      // sort=FIELD_SYSNAME_1,desc,FIELD_SYSNAME_2,asc — sorting with multiple params //, sort: 'brands_list,asc'
      .then((response) => {
        setPayload(response.payload)
        setPageInfo(response.pageInfo)
        setLoading(false)
        setPageLoading(false)
        props.onDataLoaded()
        document.querySelector('.ant-select-focused').classList.remove("ant-select-focused")
      })
      .catch((e) => {
        // handling errors
        setLoading(false)
        setPageLoading(false)
        console.log(e.response)
        setBadRequest(e.response.status + ', ' + e.response.data.msg)
      })
  }
return(
  <div>
    {loading && 
      <>
        <div className="selected-params-block"></div>
        <Loader type = 'cards' isFirstLoading={true} />
      </>
    }
      {payload && !loading &&
        <div>
            <div className="selected-params-block">
              {props.endpoint == 'get_all' && 
                	<p>{/*<span>Ваш выбор:  </span>*/}
                		{props.protectedObjectsVal.map(item => <Tag color="cyan">
                      <TagsEllipsisText value={item} maxWidth={tagMaxWidth} />
                    </Tag>)}
                		{props.pesticideTypesVal.map(item => <Tag color="red">
                      <TagsEllipsisText value={item} maxWidth={tagMaxWidth} />
                    </Tag>)}
                		{props.aimsVal.map(item => <Tag color="volcano">
                      <TagsEllipsisText value={item} maxWidth={tagMaxWidth} />
                    </Tag>)}
                		{props.brandsVal.map(item => <Tag color="green">
                      <TagsEllipsisText value={item} maxWidth={tagMaxWidth} />
                    </Tag>)}
                		{props.moaCombinationsVal.map(item => <Tag color="geekblue">
                      <TagsEllipsisText value={item} maxWidth={tagMaxWidth} />
                    </Tag>)}
                		{props.chemicalClassesCombinationsVal.map(item => <Tag color="blue">
                      <TagsEllipsisText value={item} maxWidth={tagMaxWidth} />
                    </Tag>)}
                		{props.componentsCombinationsVal.map(item => <Tag color="purple">
                      <TagsEllipsisText value={item} maxWidth={tagMaxWidth} />
                    </Tag>)}
                	</p>
              }
              {props.endpoint == 'search_all' && props.searchBlockVal != "" &&
                <p>Результат поиска по запросу:
                  <span className="selected-params-string"> «{props.searchBlockVal}»</span>
                  <span>. Для более точного поиска используйте фильтры.</span>
                </p>
              }
            </div>
          
          {pageLoading && <Loader type = 'cards' />}
          {!pageLoading && payload.length == 0 &&
            <>
              <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
              <p style={{textAlign: "center", marginTop: "2%"}}>Нет препаратов соответствующих выбранным фильтрам.</p>
            </>
          }
          {!pageLoading &&
            <div className="site-card-wrapper">
      		    <Row gutter={0,10}>
      		    	{payload.map(row =>
      			      <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
      			        <Card
                     onClick={() => showModalForCard(row)}
                      hoverable 
        				      title={row.name}
        				      style={{
                        height: '95%',
        				        borderRadius: 2.5,
        				        borderColor: "#99958C",
        				      }}
                      extra={
                        <Text 
                          style={
                            ellipsis
                                ? {
                                    maxWidth: "10em",
                                  }
                                : undefined
                          }
                          ellipsis={
                            ellipsis
                              ? {
                                  tooltip: row.brands_list,
                                }
                              : false
                          }>
                          <span>{row.brands_list}</span>
                        </Text>
                      }
                    >
          			    <Meta description={<LabelsList type="color_acronym" data={row.pesticide_types_standart_id_list}/>} />
                      <LabelMoaGroup type="multiple"  data={row.components_id_list}/>
          			      <p>{row.formulation_source}</p>
                      
                      {/*<Popover content = {`Нажмите, чтобы получить больше информации о препарате ${row.name}`} placement="top" >
                                                <Button key="" onClick={() => showModalForCard(row)}>
                                                  Подробнее
                                                </Button>
                                            </Popover>*/}
          			    </Card>
      			      </Col>
      			    )}
      		    </Row>
        		</div>
          }
          {/* Paging */}
          {!pageLoading &&
          	<Pagination simple showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`} hideOnSinglePage pageSize={pageSize} current={Number(pageNum) + 1} total={pageInfo.tableSize} onChange={onChangePagination} />
          }
          <Modal
            style={{
              top: "3%"
            }}
            bodyStyle={{
              minHeight: 700
            }}
            open={openModalForCard}
            onOk={() => setOpenModalForCard(false)}
            onCancel={() => setOpenModalForCard(false)}
            destroyOnClose="true"
            footer={[
              // <Button
              //   key="link"
              //   href="https://google.com"
              //   type="primary"
              //   loading={loading}
              //   onClick={handleOk}
              // >
              //   Подробнее
              // </Button>,
              <Button key="back" onClick={handleCancel}>
                Закрыть
              </Button>,
            ]}
            width={'95%'}
          >
            <h2>{clickedCardData.name}</h2>
            <Tabs type="card" defaultActiveKey="1" size="large">
                <Tabs.TabPane tab="Регламенты применения" key="1">
                  {screens["lg"] ?
                    <Table columns={columnsReglamentsTable} dataSource={dataReglamentsTable} pagination={false}/>
                    :
                    <Collapse>
                      {dataReglamentsTable.map(reglamentsTableRow =>
                        <Panel className="reglaments-panel-block" header={<ReglamentsPanelHeader data={reglamentsTableRow} />}>
                          <h4>{columnsReglamentsTable[2]["title"]}</h4>
                          <p>{reglamentsTableRow["norm"]}</p>
                          <h4>{columnsReglamentsTable[3]["title"]}</h4>
                          <p>{reglamentsTableRow["fullAdditional"]}</p>
                          <h4>{columnsReglamentsTable[4]["title"]}</h4>
                          <p>{reglamentsTableRow["workSum"]}</p>
                          <h4>{columnsReglamentsTable[5]["title"]}</h4>
                          <p>{reglamentsTableRow["workTime"]}</p>
                        </Panel>
                      )}
                      
                    </Collapse>
                  }
                </Tabs.TabPane>
                <Tabs.TabPane tab="Состав" key="2">
                  {screens["lg"] ?
                    <Table columns={columnsCompoundTable} dataSource={dataCompoundTable} pagination={false}/>
                    : 
                    <Collapse
                      defaultActiveKey={['0','1','2','3','4','5','6','7','8','9','10']}
                    >

                    {dataCompoundTable.map(compoundRow => 
                      <Panel header={compoundRow["componentOriginal"]} key={compoundRow["key"]}>
                          <h4>{columnsCompoundTable[0]["title"]}</h4>
                          <p>{compoundRow["componentOriginal"]}</p>
                          <h4>{columnsCompoundTable[1]["title"]}</h4>
                          <p>{compoundRow["chemicalClassGlobalWithCode"]}</p>
                          <h4>{columnsCompoundTable[2]["title"]}</h4>
                          <p>{compoundRow["modeOfActionWithCode"]}</p>
                          <h4>{columnsCompoundTable[3]["title"]}</h4>
                          <LabelMoaGroup type="single" data={compoundRow["modeOfActionLabel"]}/>
                          <h4>{columnsCompoundTable[4]["title"]}</h4>
                          <p>{compoundRow["amountWithUnitMeasurement"]}</p>
                        </Panel>
                      )}
                      
                    </Collapse>
                  }
                </Tabs.TabPane>
                <Tabs.TabPane tab="Общая информация" key="3">
                    <InfoList data={dataPesticideInfo}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Регистрационные данные" key="4">
                    <InfoList data={dataPesticideRegistrationInfo}/>
                </Tabs.TabPane>
            </Tabs>
          </Modal>

          {/*<div className="request-info">
            <span>Структура данных: <b>{dataStructure ? dataStructure : <span className="error">not provided</span>}</b></span>
            <span>API-endpoint: <b>{endpoint ? endpoint : <span className="error">not provided</span>}</b></span>
            <span>Payload: <code>{JSON.stringify(payload)}</code></span>
            <span>Payload info: <code>{JSON.stringify(pageInfo)}</code></span>
            {badRequest && <code className="error">Error: <b>{badRequest}</b></code>}
          </div>*/}
        </div>}
  </div>
)
      
}