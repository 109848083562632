import React from 'react'
import { Tag, Col, Row, Divider  } from 'antd';
import './infoList.css'

export function InfoList(props) {
    console.log(props.data);
    return (
        <div className="info-list-wrapper">
            {props.data.map(item =>
                <>  <Row>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={4}>
                            {/*<p>{item.title.toUpperCase()}</p>*/}
                            <h4>{item.title}</h4>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={20}>
                            {item.type == "text" &&
                                <p>{item.value}</p>
                            }
                            {item.type == "tags" &&
                                <p>
                                {item.value.map(tag => 
                                    <Tag>{tag}</Tag>
                                )}
                                </p>
                            }
                            {item.type == "date" &&
                                <p>{item.value}</p>
                            }
                        </Col>
                        <Col span="24">
                        </Col>
                    </Row>
                    
                    
                </>
                
            )}          
        </div>
    )
}