import React, { useState } from 'react';
import { Button, Modal, Table, Tag, Alert, Popover, Typography, Grid, Collapse, Divider} from 'antd';
import './regularGridTags.css';
import { TagsEllipsisText } from '../tagsEllipsisText/tagsEllipsisText';
import { LabelsList } from '../labelsList/labelsList';

/*const [defaultTableColumnWidth, setDefaultTableColumnWidth] = useState(window.innerWidth/2.5);*/

const { Column, ColumnGroup } = Table;
const { useBreakpoint } = Grid;
const { Panel } = Collapse;

const colorSelectedTag = 'blue';

const columnsAnalogsTable = [
    {
        title: '',
        dataIndex: 'rowName',
        key: 'rowName',
        width: '15%',
        fixed: 'left',
    },
    {
        title: '',
        dataIndex: 'analog',
        key: 'analog',
        children: [],
        render: (tags) => (
          <p>
            {tags.map((tag) => {
              return (
                <span className = "analog_tag" className = 'analog_table_tag' >
                    <Tag color={tag.color} key={tag.key}>
                        <TagsEllipsisText value={tag.key} maxWidth={tag.columnsWidth} />
                    </Tag>
                </span>
              );
            })}
          </p>
        ),
    },
    {
        title: '',
        dataIndex: 'original',
        key: 'original',
        render: (tags) => (
          <p>
            {tags.map((tag) => {
              return (
                <span className = "analog_tag" className = 'analog_table_tag' >
                    <Tag color={tag.color} key={tag.key}>
                        <TagsEllipsisText value={tag.key} maxWidth={tag.columnsWidth} />
                    </Tag>
                </span>
              );
            })}
          </p>
        ),
    },
];

const singleParamColumns = [
    {"name": "formulation", "index": "3"}, 
    {"name": "poisons_combination", "index": "4"}, 
    {"name": "chemical_classes_combination", "index": "5"}, 
    {"name": "mode_of_actions_combination", "index": "6"}, 
];

const multipleParamColumns = [ 
    {"name": "protected_objects_id_list", "index": "0"}, 
    {"name": "aims_id_list", "index": "1"},
    {"name": "brands_id_list", "index": "2"},
];

const dataAnalogsTable = [
    {
        key: '1', column: 'protected_objects', rowName: 'Защищаемые объекты', original: [{}], analog: [{}],
    },
    {
        key: '2', column: 'aims', rowName: 'Цели обработки', original: [{}], analog: [{}],
    },
    {
        key: '3', column: 'brands', rowName: 'Бренд', original: [{}], analog: [{}],
    },
    {
        key: '4', column: 'formulation', rowName: 'Формуляция', original: [{}], analog: [{}],
    },
    {
        key: '5', column: 'poisons_combination', rowName: 'Действующие вещества', original: [{}], analog: [{}],
    },
    {
        key: '6', column: 'chemical_classes_combination', rowName: 'Химические классы', original: [{}], analog: [{}],
    },
    {
        key: '7', column: 'mode_of_actions_combination', rowName: 'Механизмы действия', original: [{}], analog: [{}],
    },
];

const differenceBy = (arr1, arr2, iteratee) => {
    if (typeof iteratee === 'string') {
    const prop = iteratee
    iteratee = item => item[prop]
    }
    return arr1.filter(c => !arr2.map(iteratee).includes(iteratee(c)))
}

const difference = (arr1, arr2) => arr1.filter(x => !arr2.includes(x))
        
const intersectionBy = (arr, ...args) => {
    let iteratee = args.pop()
    if (typeof iteratee === 'string') {
    const prop = iteratee
    iteratee = item => item[prop]
    }

    return arr.filter(item1 =>
    args.every(arr2 => arr2.find(item2 => iteratee(item1) === iteratee(item2)))
    )
}

const sortedUniq = arr => [...new Set(arr)].sort()

const uniqBy = (arr, iteratee) => {
  if (typeof iteratee === 'string') {
    const prop = iteratee
    iteratee = item => item[prop]
  }

  return arr.filter(
    (x, i, self) => i === self.findIndex(y => iteratee(x) === iteratee(y))
  )
}

export function RegularGridTags(props) {
    const screens = useBreakpoint();
    let tableColumnsWidth = window.innerWidth * 0.78;

    const [openModalForAnalog, setOpenModalForAnalog] = useState(false);
    const [modalData, setModalData] = useState({});
    const [loading, setLoading] = useState(false);
    const [modalType, setModalType] = useState('');
    /*const [tableColumnsWidth, setTableColumnsWidth] = useState(window.innerWidth * 0.78);*/
    /*const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpenModalForAnalog(false);
        }, 3000);
    };*/

    const handleCancel = () => {
        setOpenModalForAnalog(false);
    };

    const showModalForAnalog = (analogData, replaceablePesticideData, type) => {
        console.log(analogData);
        let columnsCount = (type == "tank_mixture") ? analogData["pesticides_id"].length + 1 : (type == "analog") ? 2 : 0;
        if (columnsCount == 2) {
            // setTableColumnsWidth(window.innerWidth * 0.9)
            tableColumnsWidth = tableColumnsWidth * 0.9
        } else {
            // setTableColumnsWidth(window.innerWidth * 0.78)
            tableColumnsWidth = tableColumnsWidth * 0.78
        }
        console.log(columnsCount)
        setModalType(type);

        columnsAnalogsTable[1].title = analogData.name;
        columnsAnalogsTable[2].title = replaceablePesticideData.name;
        columnsAnalogsTable[1]["children"] = [];
        let groupedParameters = {
            "replaceable_pesticides": {"full": [], "intersecting": [], "differing": []}, 
            "analogs": {"full": [], "intersecting": [], "differing": []},
        }

        if (type == "tank_mixture"){

            columnsAnalogsTable[1].title = analogData.name_cut;
            //Очищаем массив с данными таблицы, убираем лишние столбцы типа column_n
            dataAnalogsTable.map((row, indx) => {
                let { key, column, rowName, original, analog } = row
                dataAnalogsTable[indx] = { key, column, rowName, original, analog }
            })

            analogData["pesticides_id"].map((component, indx) => {
                let componentName = "component_"+indx;
                dataAnalogsTable.map(row => {
                    row[componentName] = []
                })
                groupedParameters[componentName] = {"full": [], "intersecting": [], "differing": []}
                //Добавляем столбцы баковой смеси
                columnsAnalogsTable[1]["children"].push(
                    {
                        title: component.name,
                        dataIndex: componentName,
                        key: componentName,
                        render: (tags) => (
                          <p>
                            {tags.map((tag) => {
                              return (
                                <span className = "analog_tag" className = 'analog_table_tag' >
                                    <Tag color={tag.color} key={tag.key}>
                                        <TagsEllipsisText value={tag.key} maxWidth={tableColumnsWidth/(columnsCount+1)} />
                                    </Tag>
                                </span>
                              );
                            })}
                          </p>
                        ),
                    }
                )
            })

            console.log(dataAnalogsTable);
        } else {}
        

        multipleParamColumns.map(column => {

            dataAnalogsTable[column.index].analog = [];
            dataAnalogsTable[column.index].original = [];

            let groupedParameters = {
                "replaceable_pesticides": {"full": [], "intersecting": [], "differing": []}, 
                "analogs": {"full": [], "intersecting": [], "differing": []},
            }

            replaceablePesticideData[column.name].map(replaceablePesticideParameter => {
                groupedParameters["replaceable_pesticides"].full.push(replaceablePesticideParameter.name);
            })

            if (type == "analog") {
                analogData[column.name].map(analogsParameter => {
                    groupedParameters["analogs"].full.push(analogsParameter.name);
                })

                intersectionBy(replaceablePesticideData[column.name], analogData[column.name], 'name').map(intersectingParameter => {
                    groupedParameters["replaceable_pesticides"].intersecting.push(intersectingParameter.name);
                    groupedParameters["analogs"].intersecting.push(intersectingParameter.name);
                })

                difference(groupedParameters["replaceable_pesticides"].full, groupedParameters["replaceable_pesticides"].intersecting).map(differingParameter => {
                    groupedParameters["replaceable_pesticides"].differing.push(differingParameter);
                })
                difference(groupedParameters["analogs"].full, groupedParameters["analogs"].intersecting).map(differingParameter => {
                    groupedParameters["analogs"].differing.push(differingParameter);
                })

                groupedParameters["replaceable_pesticides"].intersecting = sortedUniq(groupedParameters["replaceable_pesticides"].intersecting)
                groupedParameters["replaceable_pesticides"].differing = sortedUniq(groupedParameters["replaceable_pesticides"].differing)
                groupedParameters["analogs"].intersecting = sortedUniq(groupedParameters["analogs"].intersecting)
                groupedParameters["analogs"].differing = sortedUniq(groupedParameters["analogs"].differing)

                groupedParameters["replaceable_pesticides"].intersecting.map(intersectingParameter => {
                    dataAnalogsTable[column.index].original.push({"key": intersectingParameter, "color": colorSelectedTag, "columnsWidth": tableColumnsWidth/(columnsCount+1)});
                })
                groupedParameters["replaceable_pesticides"].differing.map(differingParameter => {
                    dataAnalogsTable[column.index].original.push({"key": differingParameter, "color": '', "columnsWidth": tableColumnsWidth/(columnsCount+1)});
                })

                groupedParameters["analogs"].intersecting.map(intersectingParameter => {
                    dataAnalogsTable[column.index].analog.push({"key": intersectingParameter, "color": colorSelectedTag, "columnsWidth": tableColumnsWidth/(columnsCount+1)});
                })
                groupedParameters["analogs"].differing.map(differingParameter => {
                    dataAnalogsTable[column.index].analog.push({"key": differingParameter, "color": '', "columnsWidth": tableColumnsWidth/(columnsCount+1)});
                })
            } else if (type == "tank_mixture") {
                analogData["pesticides_id"].map((component, indx) => {
                    let componentName = "component_"+indx;
                    groupedParameters[componentName] = {"full": [], "intersecting": [], "differing": []};

                    component[column.name].map(tankMixtureComponentParameter => {
                        groupedParameters[componentName].full.push(tankMixtureComponentParameter.name);
                    })
                    intersectionBy(replaceablePesticideData[column.name], component[column.name], 'name').map(intersectingParameter => {
                        groupedParameters["replaceable_pesticides"].intersecting.push(intersectingParameter.name);
                        groupedParameters[componentName].intersecting.push(intersectingParameter.name);
                    })
                    difference(groupedParameters["replaceable_pesticides"].full, groupedParameters["replaceable_pesticides"].intersecting).map(differingParameter => {
                        groupedParameters["replaceable_pesticides"].differing.push(differingParameter);
                    })
                    difference(groupedParameters[componentName].full, groupedParameters[componentName].intersecting).map(differingParameter => {
                        groupedParameters[componentName].differing.push(differingParameter);
                    })

                    groupedParameters[componentName].intersecting = sortedUniq(groupedParameters[componentName].intersecting)
                    groupedParameters[componentName].differing = sortedUniq(groupedParameters[componentName].differing)

                    groupedParameters[componentName].intersecting.map(intersectingParameter => {
                        dataAnalogsTable[column.index][componentName].push({"key": intersectingParameter, "color": colorSelectedTag, "columnsWidth": tableColumnsWidth/(columnsCount+1)});
                    })
                    groupedParameters[componentName].differing.map(differingParameter => {
                        dataAnalogsTable[column.index][componentName].push({"key": differingParameter, "color": '', "columnsWidth": tableColumnsWidth/(columnsCount+1)});
                    })

                })

                    groupedParameters["replaceable_pesticides"].intersecting = sortedUniq(groupedParameters["replaceable_pesticides"].intersecting)
                    groupedParameters["replaceable_pesticides"].differing = sortedUniq(groupedParameters["replaceable_pesticides"].differing)
                    groupedParameters["replaceable_pesticides"].differing = difference(groupedParameters["replaceable_pesticides"].differing, groupedParameters["replaceable_pesticides"].intersecting)
                    groupedParameters["replaceable_pesticides"].intersecting.map(intersectingParameter => {
                        dataAnalogsTable[column.index].original.push({"key": intersectingParameter, "color": colorSelectedTag, "columnsWidth": tableColumnsWidth/(columnsCount+1)});
                    })
                    groupedParameters["replaceable_pesticides"].differing.map(differingParameter => {
                        dataAnalogsTable[column.index].original.push({"key": differingParameter, "color": '', "columnsWidth": tableColumnsWidth/(columnsCount+1)});
                    })
            } else {}
            // console.log(groupedParameters);
        })
        // console.log(analogData)
        singleParamColumns.map(column => {
            if (type == "analog"){
                let tagColor = (replaceablePesticideData[column.name] == analogData.formulation_id[column.name]) ? colorSelectedTag : '';
                dataAnalogsTable[column.index].original[0] = {"key": replaceablePesticideData[column.name], "color": tagColor, "columnsWidth": tableColumnsWidth/(columnsCount+1)}
                dataAnalogsTable[column.index].analog[0] = {"key": analogData.formulation_id[column.name], "color": tagColor, "columnsWidth": tableColumnsWidth/(columnsCount+1)}

            } else if (type == "tank_mixture") {
                let sumIntersectIntersectingParameter = 0;
                analogData["pesticides_id"].map((component, indx) => {
                    let componentName = "component_"+indx;
                    sumIntersectIntersectingParameter = (replaceablePesticideData[column.name] == component.formulation_id[column.name]) ? sumIntersectIntersectingParameter+1 : sumIntersectIntersectingParameter;
                    let tagColor = (replaceablePesticideData[column.name] == component.formulation_id[column.name]) ? colorSelectedTag : '';
                    let tagReplaceablePesticideColor = (sumIntersectIntersectingParameter > 0) ? colorSelectedTag : '';
                    dataAnalogsTable[column.index].original[0] = {"key": replaceablePesticideData[column.name], "color": tagReplaceablePesticideColor, "columnsWidth": tableColumnsWidth/(columnsCount+1)}
                    dataAnalogsTable[column.index][componentName][0] = {"key": component.formulation_id[column.name], "color": tagColor, "columnsWidth": tableColumnsWidth/(columnsCount+1)}
                })
            } else {}
            
        })
        setOpenModalForAnalog(true);
    };

    let processedData = [];
    let curBrandsList = [];
    {props.data.map(analogData => {
        if (analogData[props.brandsConfigList[0]].length == 1) {
            analogData["brand_labels_list"] = [{"acronym": analogData[props.brandsConfigList[0]][0][props.brandsConfigList[1]], "color": analogData[props.brandsConfigList[0]][0][props.brandsConfigList[2]]}]
        } else {
            analogData[props.brandsConfigList[0]].map(brand => {
                curBrandsList.push({"acronym": brand[props.brandsConfigList[1]], "color": brand[props.brandsConfigList[2]]});
            })
            curBrandsList = uniqBy(curBrandsList, "acronym");
            analogData["brand_labels_list"] = curBrandsList;
            curBrandsList = [];
        }
        // console.log(analogData)
        processedData.push(analogData);
    })}

    return (
        <div className="regular-grid-tags-wrapper">
                {processedData.map(analogData =>
                        <span className = "analog_tag" className = {props.size} >
                            {props.column.includes("analogs_by_") &&
                                    <Tag color={props.color} onClick={() => showModalForAnalog(analogData, props.replaceablePesticideData, 'analog')}>
                                        {
                                            <>
                                                <LabelsList type="brand_acronym" data={analogData["brand_labels_list"]}/>
                                                <TagsEllipsisText value={analogData[props.title]} maxWidth={tableColumnsWidth} clickable={true} />
                                            </>
                                        }
                                    </Tag>
                            }
                            {props.column.includes("tank_mixtures_of_") &&
                                        <Tag color = {props.color} onClick={() => showModalForAnalog(analogData, props.replaceablePesticideData, 'tank_mixture')}>
                                            {   
                                                <>
                                                    <LabelsList type="brand_acronym" data={analogData["brand_labels_list"]}/>
                                                    <TagsEllipsisText value={analogData[props.title]} maxWidth={tableColumnsWidth} clickable={true} />
                                                </>
                                            }
                                        </Tag>
                            }
                        </span>
                )}
                <Modal
                    centered
                    style={{
                      top: "3%"
                    }}
                    bodyStyle={{
                      minHeight: 700
                    }}
                    open={openModalForAnalog}
                    onOk={() => setOpenModalForAnalog(false)}
                    onCancel={() => setOpenModalForAnalog(false)}
                    destroyOnClose="true"
                    footer={[
                      // <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                      //   Колосаль Про, КМЭ
                      // </Button>,
                      // <Button
                      //   key="link"
                      //   href="https://google.com"
                      //   type="primary"
                      //   loading={loading}
                      //   onClick={handleOk}
                      // >
                      //   Комиссар, КЭ
                      // </Button>,
                      <Button key="back" onClick={handleCancel}>
                        Закрыть
                      </Button>,
                    ]}
                    width={'95%'}
                >
                {modalType == "analog" ?
                    <h2>{screens["lg"] ? "Таблица сравнения" : "Сравнение"} препаратов {columnsAnalogsTable[1].title} и {columnsAnalogsTable[2].title}</h2>
                    :
                    <h2>{screens["lg"] ? "Таблица сравнения" : "Сравнение"} компонентов баковой смеси {columnsAnalogsTable[1].title} с препаратом {columnsAnalogsTable[2].title}</h2>
                }
                {modalType == "tank_mixture" &&
                    <Alert
                      message="Внимание!"
                      description="Баковая смесь подобрана автоматически, проконсультируйтесь с представителями производителей препаратов о целесообразности её использования! Перед применением баковой смеси необходимо предварительно проверить совместимость смешиваемых препаратов."
                      type="error"
                      className="alertInModal"
                    />
                }
                    {screens["lg"] ?
                        <Table columns={columnsAnalogsTable} dataSource={dataAnalogsTable} pagination={false} bordered className="tableInModal" />
                        :
                        <Collapse className="regular-grid-tags-collapse" defaultActiveKey={['0']} accordion>
                            {dataAnalogsTable.map(row =>
                                <Panel header={row.rowName}>
                                    <h3>{columnsAnalogsTable[1].title}</h3>
                                    {modalType == "analog" &&
                                        row.analog.map(tag => 
                                            <span className = "analog_tag" className = 'analog_panel_tag' >
                                                <Tag color={tag.color} key={tag.key}>
                                                    <TagsEllipsisText value={tag.key} maxWidth={tableColumnsWidth*0.8} />
                                                </Tag>
                                            </span>
                                        )
                                    }

                                    {modalType == "tank_mixture" &&
                                        columnsAnalogsTable[1]["children"].map((component, indx) => 
                                            <>
                                            <h4 className = "tank-mixture-component-header">{component.title}</h4>
                                            {row["component_"+indx].map(tag => 
                                                <span className = "analog_tag" className = 'analog_panel_tag' >
                                                    <Tag color={tag.color} key={tag.key}>
                                                        <TagsEllipsisText value={tag.key} maxWidth={tableColumnsWidth*0.8} />
                                                    </Tag>
                                                </span>
                                            )}
                                            </>
                                        )
                                    }

                                    <Divider/>
                                    <h3>{columnsAnalogsTable[2].title}</h3>
                                    {row.original.map(tag => 
                                        <span className = "analog_tag" className = 'analog_panel_tag' >
                                            <Tag color={tag.color} key={tag.key}>
                                                <TagsEllipsisText value={tag.key} maxWidth={tableColumnsWidth*0.8} />
                                            </Tag>
                                        </span>
                                    )}
                                </Panel>

                            )}
                          
                        </Collapse>
                    }
                </Modal>
        </div>
    )
}