import { useHistory, useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { MailOutlined, LoginOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import { useAuth } from '../auth'

export default function LoginPage () {
  let history = useHistory()
  let location = useLocation()

  const [regBlockVisStatus, setRegBlockVisStatus] = useState(false)

  const handleChangeRegBlockVisibility = (event, data) => {
    setRegBlockVisStatus(true)
  }

  let { from } = location.state || { from: { pathname: '/' } }
  const auth = useAuth()

  useEffect(() => {
    if (auth.isAutorised()) {
      history.replace(from)
    }
  })

  return (
    <div className="custom-result-block">
      <Result title="Спасибо за проявленный интерес! Проект Agronomium.ru находится в стадии закрытого бета-тестирования. Нажмите кнопку ниже, чтобы запросить данные для входа, если хотите испытать систему уже сегодня. Вы можете запросить логин и пароль по почте info@agronomium.ru или войти в систему, если получили доступ ранее." 
        extra={
          <>
            <Button type="primary" key="linkToMail" size="large" icon={<MailOutlined/>} href="mailto:info@agronomium.ru?subject=Запрос логина и пароля">
              Запросить доступ
            </Button>
            <Button key="login" size="large" icon={<LoginOutlined />} onClick={() => {
                        history.push('/login');
                    }}>
              Войти
            </Button>
          </>
        }
      />
      
    </div>
  )
}