import React from 'react';
import { Typography } from 'antd';
import './tagsEllipsisText.css';
const { Text } = Typography;

export function TagsEllipsisText(props) {
  const ellipsis = true;
  const clickableTagClassName = (props.clickable) ? "custom-clickable-tag-with-modal" : "";

  return (
    <Text 
      style={
        ellipsis
            ? {
                maxWidth: props.maxWidth,
              }
            : undefined
      }
      ellipsis={
        ellipsis
          ? {
              tooltip: props.value,
            }
          : false
      }>
      <span className={clickableTagClassName}>
        {props.value}
      </span>
    </Text>
  );
};