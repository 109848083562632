import React from 'react'
import { Skeleton, Card, Grid, Col, Row, Typography } from 'antd';
import './comparedCard.css'

import { LabelsList } from '../labelsList/labelsList';

const { Meta } = Card;
const { useBreakpoint } = Grid;
const { Text } = Typography;

const ellipsis = true;

export function ComparedCard(props) {
    const screens = useBreakpoint();

    // console.log(props.data);
    return (        
            <Row>
                {props.data.map(item => 
                    <Col style={{padding: "2%"}} xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                        {props.type == "content" && 
                            <Card 
                                title={item.title}
                                className="custom-compared-card"
                                extra={!!screens["lg"] && 
                                    <Text 
                                        style={
                                            ellipsis
                                            ? {maxWidth: "10em",}
                                            : undefined
                                        }
                                        ellipsis={
                                            ellipsis
                                            ? {tooltip: item.brands,}
                                            : false
                                        }
                                    >
                                        <span>{item.brands}</span>
                                    </Text>
                                }
                            >
                            <Meta description={<LabelsList type="color_acronym" data={item.pesticide_type_label} />} />
                                <p>{item.description}</p>
                                {!screens["lg"] && <p>{item.brands}</p>}
                            </Card>
                        }
                        {props.type == "loader" && 
                            <Card 
                                className="custom-compared-card"
                                extra={<p></p>}
                            >
                                <Skeleton active />
                            </Card>
                        }
                    </Col>
                )}
            </Row>
    )
}