import React from 'react'
import { Skeleton, Card, Col, Row, Spin } from 'antd';
import './loader.css'

export function Loader(props) {
    const cardsGreed = [1,2,3,4,5,6,7,8,9,10,11,12]
    return (
        <div >
            {/*<div className="loader"></div>
                        <span className="loader-text">{props.text || 'Загрузка...'}</span>*/}
            {(props.type == "cards") &&
                <Row gutter={0,10}>
                    {cardsGreed.map(row =>
                        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                            <Card
                                style={{
                                    height: '95%',
                                    borderRadius: 2.5,
                                    borderColor: "#99958C",
                                }}
                            >
                                <Skeleton active />
                            </Card>
                        </Col>
                    )}
                </Row>
            }
            {(props.type == "lists") &&
                <Skeleton active />
            }
            {(props.type == "double_column") &&
                <>
                    <Row>
                        <Col span={12}>
                          <Skeleton active />
                        </Col>
                        <Col span={12}>
                            <Skeleton active />
                        </Col>
                    </Row>
                </>
            }
            {(props.type == "spin") &&
                <>
                    <Spin />
                </>
            }
            
        </div>
    )
}